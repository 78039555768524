import useTranslation from "next-translate/useTranslation";
import BlockGap from "../shared/gap";
import { useForm } from "react-hook-form";
import { getGeneratePin } from "../../src/queries";
import { useState, useref } from "react";
import ImageIcon from "../shared/image_icon";
import PrivacyPolicySheet from "./privacy_policy_sheet";
import TermsOfServiceSheet from "./terms_of_service_sheet";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { isValidPhoneNumber } from "libphonenumber-js";
import ReCAPTCHA from "react-google-recaptcha";

const MODALS = {
  none: null,
  terms: 1,
  privacyPolicy: 2,
};

const Numbers = (props) => {
  const { t } = useTranslation("setup");
  const [error, setError] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaV3, setRecaptchaV3] = useState(null);
  const [showModal, setShowModal] = useState(MODALS.none);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const onSubmit = (data) => {
    executeRecaptcha("generate_pin").then((token) => {
      const phone = clearPhone(data.phone);
      props.setPhone(phone);

      getGeneratePin({ phone: phone, recaptcha_token: token, recaptcha_token_v2: data.recaptcha_token_v2 })
        .then((response) => {
          if (response.data.phone.includes(phone)) {
            props.setView(props.views.verification);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.errors?.includes("Invalid recaptcha validation")) {
            setRecaptchaV3("failed");
          }
          console.log(error);
          setError(error.response?.data?.errors?.join(", "));
        });
    });
  };

  const checkPhoneNumber = (phone) => {
    return isValidPhoneNumber(clearPhone(phone));
  };

  const handlePhoneChange = (val) => {
    let value = val?.startsWith("+") ? val : `+${val}`;
    value = value.startsWith("++") ? value.slice(1, value.length) : value;
    setValue("phone", value);
  };

  const clearPhone = (phone) => (phone ? phone.replaceAll(/\s|-|_|\(|\)/g, "") : "");

  return (
    <div className="container-sm min-vh-100 position-relative">
      <h1 className="pt-90">
        {t("number.title")} <br /> {t("number.title2")}
      </h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-36">
          <div className="col-12 mb-1">
            <input
              type="tel"
              className="form-control form-control-lg"
              placeholder="+420 000 000 000"
              autoComplete="off"
              onFocus={(e) => (!e.target.value ? setValue("phone", "+") : null)}
              {...register("phone", {
                required: true,
                validate: checkPhoneNumber,
                onChange: (e) => handlePhoneChange(e.target.value),
                onFocus: (e) => handlePhoneChange(e.target.value),
                onBlur: (e) => (e.target.value === "+" ? setValue("phone", "") : null),
              })}
            />
            <p className="mt-2">
              <small className="label-3 text-gray1">{t("number.hint")}</small>
            </p>
          </div>

          {error && <span className="text-danger">{error}</span>}

          {recaptchaV3 && recaptchaV3 == "failed" && (
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V2}
              onChange={(value) => {
                setValue("recaptcha_token_v2", value);
                setError("");
              }}
            />
          )}

          <div className="mt-2">
            <label className="ms-1 d-flex" htmlFor="privacy_policy">
              <span className="d-flex" onClick={() => setPrivacyPolicy(!privacyPolicy)}>
                <ImageIcon name={privacyPolicy ? "checkbox-on" : "checkbox-off"} />
                {`${t("get_started.agree")}`}
              </span>
              <a className="ms-1" onClick={() => setShowModal(MODALS.privacyPolicy)}>
                {` ${t("get_started.privacy_policy").toLowerCase()}`}
              </a>
            </label>

            <label className="ms-1 mt-1 d-flex" htmlFor="privacy_policy">
              <span className="d-flex" onClick={() => setTermsOfService(!termsOfService)}>
                <ImageIcon name={termsOfService ? "checkbox-on" : "checkbox-off"} /> {`${t("get_started.agree")}`}
              </span>
              <a className="ms-1" onClick={() => setShowModal(MODALS.terms)}>
                {` ${t("get_started.terms_of_use").toLowerCase()}`}
              </a>
            </label>
          </div>

          <div className="d-block mb-4">
            <BlockGap>
              <input
                type="submit"
                disabled={!isDirty || !isValid || !privacyPolicy || !termsOfService}
                className="btn btn-primary btn-lg mt-4 px-0"
                value={t("common:next")}
              />
            </BlockGap>
          </div>
        </div>
      </form>

      <TermsOfServiceSheet isOpen={showModal === MODALS.terms} onClose={() => setShowModal(MODALS.none)} />
      <PrivacyPolicySheet isOpen={showModal === MODALS.privacyPolicy} onClose={() => setShowModal(MODALS.none)} />
    </div>
  );
};

export default Numbers;
