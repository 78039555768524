
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import GetStarted from "../../components/sign_in/get_started";
import Number from "../../components/sign_in/number";
import Verification from "../../components/sign_in/verification";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { REDIRECT_URL_REGEX, getAfterSignInPath, userSetupStatusRedirect } from "../../helpers/application";
const VIEWS = {
    getStarted: 1,
    number: 2,
    verification: 3
};
const Index = () => {
    const [view, setView] = useState(VIEWS.getStarted);
    const [phone, setPhone] = useState("");
    const router = useRouter();
    const { data: session } = useSession();
    useEffect(() => {
        const setup_status = session?.user?.setup_status;
        if (session && session.user) {
            let afterSignInRedirect = "/";
            if (typeof window !== undefined) {
                const redirect = getAfterSignInPath(sessionStorage);
                if (redirect && REDIRECT_URL_REGEX.test(redirect)) {
                    afterSignInRedirect = redirect;
                }
                else {
                    sessionStorage.removeItem("after_sign_in_path");
                }
            }
            if (router && setup_status) {
                if (setup_status === "finished") {
                    sessionStorage.removeItem("after_sign_in_path");
                }
            }
            router.push(userSetupStatusRedirect(setup_status, afterSignInRedirect));
        }
    }, [session]);
    useEffect(() => {
        if (typeof window === undefined)
            return;
        if (session && session.user)
            return;
        const afterSignInPath = router.query?.redirectTo;
        if (afterSignInPath && REDIRECT_URL_REGEX.test(afterSignInPath)) {
            sessionStorage.setItem("after_sign_in_path", Buffer.from(afterSignInPath).toString("base64"));
        }
    }, [router.query]);
    return (<GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY} scriptProps={{ async: true, defer: false }}>
      {view == VIEWS.getStarted && <GetStarted views={VIEWS} setView={setView}/>}

      {view == VIEWS.number && <Number views={VIEWS} setPhone={setPhone} setView={setView}/>}

      {view == VIEWS.verification && phone && <Verification views={VIEWS} phone={phone} setView={setView}/>}
    </GoogleReCaptchaProvider>);
};
export default Index;

    async function __Next_Translate__getStaticProps__18f51d39be0__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/sign_in/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18f51d39be0__ as getStaticProps }
  