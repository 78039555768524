import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import BlockGap from "../shared/gap";
import PrivacyPolicySheet from "./privacy_policy_sheet";
import TermsOfServiceSheet from "./terms_of_service_sheet";
import dynamic from "next/dynamic";
import Image from "next/image";
const CookieConsentConditional = dynamic(() => import("../shared/cookie_consent_conditional"), { ssr: false });

const MODALS = {
  none: null,
  terms: 1,
  privacyPolicy: 2,
};

const GetStarted = (props) => {
  const { t } = useTranslation("setup");
  const [showModal, setShowModal] = useState(MODALS.none);

  return (
    <div>
      <div className="hero text-white">
        {/* <img src="/images/bg.svg" alt="Unalanu" title="Unalanu" className="img" /> */}
        <div className="img-overlay">
          <img
            src="/images/logo.svg"
            alt="Unalanu"
            title="Unalanu"
            className="mx-auto d-block mt-3"
            width="auto"
            height="auto"
          />
          <h1 className="display-title mt-2 text-center">{t("get_started.title")}</h1>
        </div>
      </div>

      <div className="container mt-4">
        <BlockGap>
          <button className="btn btn-primary" onClick={() => props.setView(props.views.number)}>
            {t("get_started.button")}
          </button>
        </BlockGap>

        <div className="text-center mt-3 label-3">
          <span>
            {`${t("get_started.terms")} `}
            <span onClick={() => setShowModal(MODALS.terms)} className="text-accent1">
              {t("get_started.terms_of_use")}
            </span>
            {` ${t("get_started.and")} `}
            <span onClick={() => setShowModal(MODALS.privacyPolicy)} className="text-accent1">
              {t("get_started.privacy_policy")}
            </span>
            <CookieConsentConditional>
              <div className="mt-2">
                <span data-cc="c-settings" className="text-accent1">
                  {t("common:profile.cookie")}
                </span>
              </div>
            </CookieConsentConditional>
          </span>
        </div>

        <CookieConsentConditional>
          <div className="row mt-4">
            <div className="col-6">
              <a href="https://apps.apple.com/us/app/unalanu/id1642908664" rel="external">
                <button
                  className="btn btn-sm btn-outline-primary float-end p-2 d-flex justify-content-center align-content-center"
                  style={{ width: 155, height: 45 }}
                >
                  <Image src="/images/app-store-icon.png" width={24} height={24} alt="App Store" layout="raw" />{" "}
                  <span className="ms-1">App Store</span>
                </button>
              </a>
            </div>

            <div className="col-6">
              <a href="https://play.google.com/store/apps/details?id=com.railsformers.unalanu" rel="external">
                <button
                  className="btn btn-sm btn-outline-primary p-2 d-flex justify-content-center align-content-center"
                  style={{ width: 155, height: 45 }}
                >
                  <Image src="/images/google-play-icon.png" width={24} height={24} alt="Google Play" layout="raw" />{" "}
                  <span className="ms-1">Google Play</span>
                </button>
              </a>
            </div>
          </div>
        </CookieConsentConditional>
      </div>

      <TermsOfServiceSheet isOpen={showModal === MODALS.terms} onClose={() => setShowModal(MODALS.none)} />
      <PrivacyPolicySheet isOpen={showModal === MODALS.privacyPolicy} onClose={() => setShowModal(MODALS.none)} />
    </div>
  );
};

export default GetStarted;
