import Loader from "../shared/loader";
import { useQuery } from "@tanstack/react-query";
import { getTermsOfService } from "../../src/queries";

const TermsOfServiceText = () => {
  const { data, isFetching } = useQuery(["terms_of_service"], getTermsOfService);

  return (
    <div>
      {data?.terms_of_service && <div dangerouslySetInnerHTML={{ __html: data.terms_of_service }} />}
      <Loader visible={isFetching} />
    </div>
  );
};

export default TermsOfServiceText;
