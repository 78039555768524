import CustomSheet from "./custom_sheet";
import { XLg } from "react-bootstrap-icons";

const CloseSheet = (props) => {
  const { children, ...passProps } = props;

  return (
    <CustomSheet
      {...passProps}
      header={
        <div className="m-2">
          <XLg className="m-2" onClick={props.onClose} />
        </div>
      }
    >
      {props.children}
    </CustomSheet>
  );
};

export default CloseSheet;
