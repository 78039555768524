import { AsYouType } from "libphonenumber-js";
import { signIn } from "next-auth/react";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { PinInput } from "react-input-pin-code";
import { useTimer } from "react-timer-hook";
import { getGeneratePin } from "../../src/queries";
import Navbar from "../navbars/navbar";
import ImageIcon from "../shared/image_icon";
import Loader from "../shared/loader";

const RESEND_SECONDS = 60;

const Verification = (props) => {
  const [pinValue, setPinValue] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation("setup");
  const [canVerifyPin, setCanVerifyPin] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (canVerifyPin && !isValid) {
      verifyPinRequest();
    }
  }, [canVerifyPin]);

  const verifyPinRequest = async () => {
    const enteredPin = pinValue.join("");

    const result = await signIn("credentials", {
      phone: props.phone,
      pin: enteredPin,
      redirect: false,
    });

    if (result && result.status !== 200) {
      setError(t("verification.invalid_pin"));
    } else {
      setIsValid(true);
    }
    setShowLoader(false);
    setCanVerifyPin(false);
  };

  const expiryTimestamp = () => {
    const time = new Date();
    return time.setSeconds(time.getSeconds() + RESEND_SECONDS);
  };

  const { seconds, isRunning, restart } = useTimer({
    expiryTimestamp: expiryTimestamp(),
  });

  const verifyPin = () => {
    setTimeout(() => {
      setCanVerifyPin(true);
      setShowLoader(true);
    }, 300);
  };

  const clearValues = () => {
    setPinValue(["", "", "", ""]);
    setError("");
  };

  const resendCode = () => {
    clearValues();
    restart(expiryTimestamp());
    getGeneratePin(props.phone);
  };

  const goBack = (e) => {
    e.preventDefault();
    clearValues();
    props.setView(props.views.number);
  };

  const handleChange = (value, index, values) => {
    setPinValue(values);
    setError("");
  };

  return (
    <>
      <div className="container-sm min-vh-100 position-relative pt-70">
        <Navbar
          left={
            <li className="nav-item">
              <a className="nav-link float-start ms-0 ps-0" onClick={goBack}>
                <ImageIcon name="back" />
              </a>
            </li>
          }
        />

        <h1 className="mt-3 mb-36">{t("verification.title")}</h1>
        <div className="row">
          <div className="d-flex justify-content-center">
            <PinInput
              values={pinValue}
              onChange={handleChange}
              size="lg"
              placeholder="·"
              autoFocus={true}
              focusBorderColor="#49BCD6"
              onComplete={verifyPin}
              showState={isValid || error !== ""}
              validate={error ? "abcd" : null}
            />
          </div>

          <div className="mt-2 mb-1">
            <small className="label-3 text-gray1">
              {t("verification.hint", { phone: new AsYouType().input(props.phone) })}
            </small>
          </div>

          {!isValid && (
            <div>
              {isRunning ? (
                <span className="label-2 text-gray2">
                  {seconds === 0 ? "" : t("verification.resend_in", { seconds: seconds })}
                </span>
              ) : (
                <a className="label-1" onClick={resendCode}>
                  {t("verification.resend")}
                </a>
              )}
            </div>
          )}
          <div className="mt-2 text-danger">{error}</div>
          <div className="text-center mt-2">
            <Loader visible={showLoader} />
          </div>

          {/* <div className="d-block mb-4 mt-4">
            <BlockGap>
              <button onClick={canVerifyPin} disabled={pinValue.join("").length !== 4} className="btn btn-primary btn-lg">
                {t("common:next")}
              </button>
            </BlockGap>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Verification;
