import CloseSheet from "../shared/close_sheet";
import TermsOfServiceText from "../gdpr/terms_of_service_text";

const TermsOfServiceSheet = (props) => {
  return (
    <CloseSheet isOpen={props.isOpen} onClose={props.onClose} onTapOut={props.onClose} disableDrag={true}>
      {props.isOpen && <TermsOfServiceText />}
    </CloseSheet>
  );
};

export default TermsOfServiceSheet;
