const Loader = (props) => {
  return (
    props.visible && (
      <div className={`${props.className} mb-2`}>
        <div className="spinner-grow spinner-grow-sm text-accent1" role="status"></div>
        <div className="spinner-grow spinner-grow-sm text-accent2" role="status"></div>
        <div className="spinner-grow spinner-grow-sm text-accent3" role="status"></div>
        <div className="spinner-grow spinner-grow-sm text-accent4" role="status"></div>
      </div>
    )
  );
};

export default Loader;
