import Sheet from "react-modal-sheet";
import PropTypes from "prop-types";

const CustomSheet = (props) => {
  const { header, children, onTapOut, wrapperClassName, ...passProps } = props;

  return (
    <Sheet {...passProps}>
      <Sheet.Container>
        {props.header ? <Sheet.Header>{props.header}</Sheet.Header> : <Sheet.Header />}
        <Sheet.Content>
          <Sheet.Scroller>
            <div className={props.wrapperClassName}>{props.children}</div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop onTap={props.onTapOut} />
    </Sheet>
  );
};

export default CustomSheet;

CustomSheet.defaultProps = {
  wrapperClassName: "container-sm",
};
